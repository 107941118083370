import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { SETTING } from "../setting";

const Meta: FC<{
  title?: string,
  meta?: React.ReactNode,
}> = ({
  title,
  meta,
}) => 
    <Helmet>
      {/* {!!title && <title>{`${title} ${process.env.REACT_APP_PROJECT_TITLE ?? ''}`}</title>} */}
      <title>{`${SETTING?.currentPortal?.title} ${title || ''}`}</title>
      <meta name="description" content={SETTING?.currentPortal?.description || ''} />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <link rel="icon" href={SETTING?.currentPortal?.icon} />
      {!!meta && meta}
    </Helmet>


export default Meta;
