import { FC, ReactNode, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useTicket from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IOrderTabs } from "../../pages/Order.page";
import { getActiveOrderItem, getOrder } from "../../store/orderSlice";
import Input from "../form/Input";
import OrderCancel from "./OrderCancel";
import OrderContact from "./OrderContact";
import OrderDetails from "./OrderDetails";
import OrderReturnItem from "./OrderReturnItem";
import OrderShipment from "./OrderShipment";
import OrderWarranty from "./OrderWarranty";

const OrderTabs: FC<{ tab?: IOrderTabs }> = ({ tab = 'order' }) => {
  const order = useTypedSelector(getOrder());
  const activeOrderItem = useTypedSelector(getActiveOrderItem());
  const [tabSelect, setTabSelect] = useState<IOrderTabs>(tab);
  const navigate = useNavigate();
  const { refreshTicketsWithoutToast } = useTicket();

  const orderTabs: Record<IOrderTabs, { title: string, value: ReactNode }> = {
    order:    { title: 'Order Details',   value: !!order ? <OrderDetails {...order} /> : null },
    shipment: { title: 'Track Shipment',  value: !!order ? <OrderShipment {...order} /> : null },
    warranty: { title: 'Warranty Claim',  value: !!order && !!activeOrderItem ? <OrderWarranty {...activeOrderItem} /> : null },
    return:   { title: 'Return Item',     value: !!order && !!activeOrderItem ? <OrderReturnItem {...activeOrderItem} /> : null },
    cancel:   { title: 'Cancel Order',    value: !!order ? <OrderCancel {...order} /> : null },
    contact:  { title: 'Contact Us',      value: !!order ? <OrderContact /> : null },
  };  

  console.log('order = ', order);
  

  return !order
    ? null
    : (
      <div className="">
        <div className="hidden sm:block">
          <nav className="flex gap-4 justify-between bg-[#ECECEC] rounded-md p-[6px]" aria-label="Tabs">
            {Object.entries(orderTabs).map(([key, { title }]) => (
              <NavLink
                key={`tab-title-${key}`}
                onClick={refreshTicketsWithoutToast}
                to={`${process.env.REACT_APP_BASE_URL}order/${key}`}
                className={`
                  rounded-md px-3 py-2 text-sm font-medium flex-1 text-center
                  ${tab === key || (!orderTabs?.[tab] && key === 'order')
                    ? 'bg-white text-gray-800 shadow-sm'
                    : 'text-gray-600 hover:text-gray-800'
                  }
                `}
              >
                {title}
              </NavLink>     
            ))}
          </nav>
        </div>

        <div className="sm:hidden sticky top-[90px] z-50">
          <nav className="flex gap-4 px-2 bg-[#ECECEC] rounded-md p-[6px]" aria-label="Tabs">
            <Input
              className="w-full"
              size="large"
              name="tab"
              value={tabSelect}
              handle={(e) => {
                setTabSelect(() => e.target.value as IOrderTabs);
                navigate(`${process.env.REACT_APP_BASE_URL}order/${e.target.value}`);
              }}
              options={Object.entries(orderTabs).map(([key, { title }]) => ({
                title,
                value: key,
              }))}
            />
          </nav>
        </div>

        <div className="border-border_color border-[1px] mt-4 rounded-md p-4">
          {orderTabs?.[tab]?.value ?? orderTabs['order'].value}
        </div>      
      </div>
    )
}

export default OrderTabs;
