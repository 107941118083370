import logoAllusedparts from './components/Logo/allusedparts.png';
import logoAutoenginesoutlet from './components/Logo/autoenginesoutlet.svg';
import logoAutomotix from './components/Logo/automotix.png';
import logoBuyusedengine from './components/Logo/buyusedengine.png';

type IPortalTitle = 'automotix' | 'allusedparts' | 'autoenginesoutlet' | 'buyusedengine';
interface IPortalSetting {
  logo: string,
  icon: string,
  title: string,
  description: string,
  agreementUrl: string,
}
type IPortal = Record<IPortalTitle, IPortalSetting>;

const portals: IPortal = {
  automotix: {
    logo: logoAutomotix,
    icon: logoAutomotix,
    title: 'Automotix',
    description: 'Automotix',
    agreementUrl: 'https://automotix.net/return-policy.html',
  },
  allusedparts: {
    logo: logoAllusedparts,
    icon: logoAllusedparts,
    title: 'Allusedparts',
    description: 'Allusedparts',
    agreementUrl: 'https://allusedparts.com/returns.html',
  },
  autoenginesoutlet: {
    logo: logoAutoenginesoutlet,
    icon: logoAutoenginesoutlet,
    title: 'Autoenginesoutlet',
    description: 'Autoenginesoutlet',
    agreementUrl: 'https://automotix.net/return-policy.html',
  },
  buyusedengine: {
    logo: logoBuyusedengine,
    icon: logoBuyusedengine,
    title: 'Buyusedengine',
    description: 'Buyusedengine',
    agreementUrl: 'https://shop.buyusedengine.com/returns.html',
  },
};

export const SETTING = {
  authHistoryStorageTitle: 'auth-params-history',
  portals,
  currentPortal: portals[Object.keys(portals).find((title) => window.location.origin.includes(title)) as IPortalTitle || 'automotix']
}