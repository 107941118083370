import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHandleField } from "../../hooks/useHandleField";
import useTicket, { IMsgForm } from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import { getDictionary } from "../../store/ticketCommonSlice";
import { getTickets } from "../../store/ticketSlice";
import Loading from "../common/Loading";
import RadioList from "../form/RadioList";
import TicketMessage from "../tickets/TicketMessage";
import TicketMessages from "../tickets/TicketMessages";
import TimeLineVertical from "../TimeLineVertical";
import SubTitle from "../title/SubTitle";
import Title from "../title/Title";
import { IOrder } from "../../model/IOrder";

export interface ICancelForm {
  additionalDetails: string,
  attachments?: File[],
  reason: string,
}

const OrderCancel: FC<IOrder> = ({
  siTrackingNum
}) => {
  const tickets = useTypedSelector(getTickets());
  const dictionary = useTypedSelector(getDictionary());
  const { addCancel, addMsg } = useTicket();
  const [loading, setLoading] = useState<boolean>(false);

  // -- Order has been already shipped (it's impossible to cancel)



  // --
  // -- Add Ticket flow
  // --
  const initReturnForm: ICancelForm = {
    additionalDetails: '',
    attachments: [],
    reason: '',
  };

  const [state, setState] = useState<ICancelForm>(initReturnForm);
  const [stateErr, setStateErr] = useState<Partial<Record<keyof ICancelForm, string>>>();
  const handleField = useHandleField(setState);

  const handleAddTicket = async () => {
    setLoading(() => true);
    setStateErr(() => undefined);
    const errs = await addCancel(state);
    if (errs)
      setStateErr(() => errs);
    else {
      toast.success(msg('cancel order request was sended successfully'));
      setState(() => initReturnForm);
    }
    setLoading(() => false);
  };


  // --
  // -- Edit the existed ticket flow
  // --
  const [currentTicket, setcurrentTicket] = useState<ITicket | null>();
  const { searchTicketByTypeIds } = useTicket();
  useEffect(() => {
    setcurrentTicket(() => searchTicketByTypeIds({
      typeIds: [3],
      ticketArr: tickets,
    }))
  }, [tickets]);

  const initMsgForm: IMsgForm = {
    message: '',
    ticketId: currentTicket?.id ? String(currentTicket.id) : '',
  };

  const [stateMsg, setStateMsg] = useState<IMsgForm>(initMsgForm);
  const [stateMsgErr, setStateMsgErr] = useState<Partial<Record<keyof IMsgForm, string>>>();
  const handleMsgField = useHandleField(setStateMsg);

  useEffect(() => {
    setStateMsg((prevState) => ({
      ...prevState,
      ticketId: currentTicket?.id ? String(currentTicket.id) : '',
    }));
  }, [currentTicket?.id]);

  const handleAddMsg = async () => {
    setLoading(() => true);
    setStateMsgErr(() => undefined);
    const errs = await addMsg(stateMsg);
    if (errs)
      setStateMsgErr(() => errs);
    else {
      toast.success(msg('message was sended successfully'));
      setStateMsg(() => initMsgForm);
    }
    setLoading(() => false);
  };


  console.log('currentTicket ', currentTicket);
  

  return (
  siTrackingNum && (
    <div className="flex flex-col gap-4">
      <Title>Cancel Order</Title>
      <div>
        Your order has shipped and cannot be cancelled. If you need to return your order, you may request a Return from the Return Item menu option once your order has been delivered.
      </div>
    </div>
  )) 
  || (currentTicket === undefined && <Loading />)
  || (
      <div className={`flex flex-col gap-4 ${!!loading && 'animate-pulse'}`}>
        <Title>Cancel Order</Title>

        <div className="">
          Please submit the form below to request canceling your order. We’ll try to halt the order’s shipment. We cannot guarantee that we can stop every order from shipping. If we cannot stop the shipment you may initiate a return request from the Return Item menu option after your order has been delivered.
        </div>
        {/* <div>
          Please submit the form below to request to cancel your order.
          We will attempt to stop the order before it ships. If the order already shipped, we will attempt to turn the part around.
          The refund will be less the original freight, plus the cost to return the part back to us.
        </div>
        <div>
          If we are unable to turn it around, you will need to start the order return process.          
        </div> */}

        {!!currentTicket && (
          <>
            <SubTitle>Cancel Order Status</SubTitle>
            <TimeLineVertical ticket={currentTicket} />
          </>
        )}

        {currentTicket === null
          ? (
            <>
              <SubTitle>Select the reason why you want to cancel this order (required)</SubTitle>
              {!!dictionary?.cancel?.length && (
                <RadioList
                  name="reason"
                  value={state.reason}
                  handle={handleField('reason')}
                  options={dictionary.cancel.map(({ id, type, text }) => ({
                    title: text,
                    value: id,
                  }))}
                  error={stateErr?.reason || ''}
                />
              )}
              <SubTitle>Please provide any additional details (required)</SubTitle>

              <TicketMessage
                label="Additional Details"
                name="details"
                placeholder="Please describe in detail"
                value={state.additionalDetails}
                handle={handleField('additionalDetails')}
                error={stateErr?.additionalDetails || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setState((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={state.attachments}
                handleSend={handleAddTicket}
                preButton={(
                  <div className="">
                    Cancel Order requests received after 4pm eastern time will be processed the next business day
                  </div>
                )}
              />
            </>
          ) : (
            <>
              {currentTicket?.messages?.length && (
                <div className="mt-6">
                  <div className="mb-4 font-semibold">Messages</div>
                  <TicketMessages ticket={currentTicket} />
                </div>
              )}
              <TicketMessage
                label={!currentTicket?.messages?.length ? 'Additional Details' : ''}
                name="details"
                placeholder={!currentTicket?.messages?.length ? 'Please describe in additional detail' : 'Message Customer Support...'}
                value={stateMsg.message}
                handle={handleMsgField('message')}
                error={stateMsgErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setStateMsg((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={stateMsg.attachments}
                handleSend={handleAddMsg}
                butTitle={!currentTicket?.messages?.length ? 'Continue' : 'Send Message'}
              />
            </>
          )
        }
      </div>
  );
};

export default OrderCancel;
