import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Logo from '../components/Logo/Logo';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { WrapInLayout } from '../layouts';
import { getOrder } from '../store/orderSlice';
import priceFormatter from '../utils/priceFormatter';
import Title from '../components/title/Title';
import arrayToNodeString from '../utils/arrayToNodeString';
import { NavLink } from 'react-router-dom';
import parseISOToString from '../utils/parseISOToString';

export type IOrderTabs = 'order' | 'shipment' | 'warranty' | 'return' | 'cancel' | 'contact';

const InvoicePage: FC = () => {
  const order = useTypedSelector(getOrder());
  const navigate = useNavigate();

  useEffect(() => { !order && navigate(`${process.env.REACT_APP_BASE_URL}order`) }, []);

  return (
    <WrapInLayout title="Order Invoice">
      {!order
        ? null
        : (
          <div className="flex flex-col py-2 sm:py-4 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-[1100px] mx-auto flex flex-col gap-4">
              <div className="flex justify-between">
                <div className="flex flex-col gap-3">
                  <Logo size="large" />
                  <div className="text-3xl sm:text-5xl title-bold">ORDER INVOICE</div>
                  <div>
                    <div><strong>Order Placed:</strong> {parseISOToString(order.timeCreate, false)}</div>
                    <div><strong>Order #:</strong> {order.orderId}</div>
                    <div><strong>Order Total:</strong> {priceFormatter(order.totalPrice)}</div>
                  </div>                
                </div>
                <div className=""></div>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <div className="border-border_color border-[1px] rounded-md p-6">
                  {(order?.orderItems || []).map((orderItem) => (
                    <div key={`invoice-order-item-${orderItem.id}`} className='flex  justify-between'>
                      <div className="flex flex-col gap-4">
                        <Title>Items Ordered</Title>
                        <div>
                          <div className="font-bold">
                            {arrayToNodeString(' ', [orderItem.viYear, orderItem.viMake, orderItem.name])}
                          </div>
                          {arrayToNodeString(<><br/></>, [orderItem.viModel, orderItem.name, `Part#: ${orderItem.pid}`])}
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        Price
                        <strong>{priceFormatter(orderItem.price)}</strong>
                      </div>
                    </div>
                  ))}                
                </div>
                <div className="border-border_color border-[1px] rounded-md p-6">
                  <Title>Shipping Address</Title>
                  <div className="mt-3">
                    {arrayToNodeString(<><br/></>, [order.siName, arrayToNodeString(', ', [order.siStreet, order.siState, order.siZip, order.siCountry])])}
                  </div>
                </div>
                <div className="border-border_color border-[1px] rounded-md p-6 flex flex-col sm:flex-row gap-6 justify-between">
                  <div>
                    <Title>Payment Information</Title>
                    <div className="mt-3 flex flex-col gap-2">
                      <strong>Billing Address</strong>                  
                      {arrayToNodeString(<><br/></>, [order.piName, order.siCompany, order.piPhone, order.piEmail])}
                    </div>
                  </div>
                  <div className="flex flex-col w-[250px]">
                    <strong>Order Summary</strong>
                    <div className='flex flex-1 justify-between mt-2'><div>Subtotal:</div> {priceFormatter(order.subtotalPrice)}</div>
                    <div className='flex flex-1 justify-between'><div>Local Discount:</div> {priceFormatter(order.discountAmount)}</div>
                    <div className='flex justify-between'><div>Coupon (No Coupon):</div> {priceFormatter(order?.couponCode || 0)}</div>
                    <div className='flex justify-between'><div>Shipping:</div> {priceFormatter(order.shipping)}</div>
                    <div className="text-right">-----</div>
                    <div className='flex justify-between'><div>Total before tax:</div> {priceFormatter(order.subtotalPrice)}</div>
                    <div className='flex justify-between'><div>Estimated tax:</div> {priceFormatter(order.taxes)}</div>                  
                    <div className="text-right">-----</div>
                    <div className='flex justify-between'><strong>Total Amount:</strong> {priceFormatter(order.totalPrice)}</div> 
                  </div>
                </div>
                <div className="text-center font-semibold">To view the status of your order, return to <NavLink to="/order" className="text-red-600">Order Details</NavLink></div>
              </div>            
            </div>
          </div>
        )
      }
    </WrapInLayout>
  );
};

export default InvoicePage;
