import { FC } from 'react';
import { SETTING } from '../../setting';

const Logo: FC<{ size?: 'small' | 'medium' | 'large', className?: string, }> = ({
  size = 'medium',
  className,
}) => (
  <div className={`
    ${className || ''}
    inline-flex relative items-center            
  `}>
    <img 
      src={SETTING.currentPortal.logo}
      className={{
          'small': 'h-[35px] sm:h-[49px]',
          'medium': 'h-[45px] sm:h-[62px]',
          'large': 'h-[55px] sm:h-[80px]',
        }[size]
      }
    />
  </div>

);


export default Logo;
