import React, { FC, PropsWithChildren } from "react";
import Meta from "./Meta";
import CenterLayout from "./center";
import OrderLayout from "./order";
import Col1Layout from "./col1";
import { SETTING } from "../setting";

// -- Templates list
const layouts = {
  'order': OrderLayout,
  'center': CenterLayout,
  'col1': Col1Layout,
}
export type TLayouts = keyof typeof layouts;

// --
// -- Wrap page into templates
// --
export const WrapInLayout: FC<PropsWithChildren<{
  title?: string,
  meta?: React.ReactNode,
  layout?: TLayouts,
}>> = ({
  title,
  meta,
  children,
  layout,
}) => {
  const Layout = layout && layouts?.[layout];
  return (
    <>
      <Meta 
        title={title}
        meta={meta}
      />
      {Layout
        ? <Layout >{children}</Layout>
        : children
      }
    </>
  );
};
