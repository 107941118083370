import toast from "react-hot-toast";
import { z } from "zod";
import { getErrorFromCatch, getOrderAuthParam, serializeFieldErrors } from "../api/api";
import { ticketApi } from "../api/ticket.api";
import { ICancelForm } from "../components/order/OrderCancel";
import { IContactForm } from "../components/order/OrderContact";
import { IReturnForm } from "../components/order/OrderReturnItem";
import { IWarrantyForm } from "../components/order/OrderWarranty";
import { IRequestOrderAuth } from "../model/IOrder";
import { ITicket, ITicketTypeName } from "../model/ITicket";
import { msg } from "../msg";
import { getTickets, setTickets } from "../store/ticketSlice";
import checkFormStateByZod from "../utils/checkFormStateByZod";
import packToFormData from "../utils/packToFormData";
import { useTypedSelector } from "./useTypedSelector";
import { useTypedDispatch } from "./useTypedDispatch";
import { removeOrder } from "../store/orderSlice";
import { useNavigate } from "react-router";
import useOrder from "./useOrder";

export type IRequestReturn = IRequestOrderAuth & {
  reason: string | number,
  additionalDetails: string,
}
export type ICancelReturn = IRequestOrderAuth & {
  orderItemId: number,
  reason: string | number,
  additionalDetails: string,
}
export type IRequestWarraty = IRequestOrderAuth & {
  orderItemId: number,
  additionalDetails: string,
}
export type IRequestConact = IRequestOrderAuth & {
  message: string,
}
export type IMsgForm = {
  message: string,
  attachments?: File[],
  ticketId: string,
}
export type IRequestMsg = IRequestOrderAuth & Omit<IMsgForm, 'ticketId'>;


// --
const MessageScheme = z.object({
  message: z
    .string()
    .min(1, msg('empty message')),
} as Record<keyof IMsgForm, any>);

const contactFormScheme = z.object({
  message: z
    .string()
    .min(1, msg('empty message')),
} as Record<keyof IContactForm, any>);

const warrantyFormScheme = z.object({
  additionalDetails: z
    .string()
    .min(1, msg('empty message')),
} as Record<keyof IWarrantyForm, any>);

const returnFormScheme = z.object({
  // additionalDetails: z
  //   .string()
  //   .min(1, msg('empty message')),
  reason: z
    .string()
    .min(1, msg('empty reason')),    
} as Record<keyof IReturnForm, any>);

const cancelFormScheme = z.object({
  additionalDetails: z
    .string()
    .min(1, msg('empty message')),
  reason: z
    .string()
    .min(1, msg('empty reason')),    
} as Record<keyof ICancelForm, any>);

export default function useTicket () {
  const tickets = useTypedSelector(getTickets());
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { removeOrderAuthParams } = useOrder();

  const getTicketsByType = (typeName: ITicketTypeName): ITicket[] | undefined => (tickets || [])
    .filter(({ type: { name } }) => name === typeName);

  const [refreshTicketsApi] = ticketApi.useLazyGetTicketsQuery();
  const refreshTickets = (isToast = true) => async () => {
    try {
      const tickets = await refreshTicketsApi({}).unwrap();
      dispatch(setTickets(tickets))
      isToast && toast.success(msg('the order was successfully refreshed'));
    } catch (err) {
      toast.error(msg('failed to refresh the order'));
    }
  };

  const searchTicket = ({
    typeName,
    searchOrderItemId,
    ticketArr,
  } : {
    typeName?: ITicketTypeName,
    searchOrderItemId?: ITicket['orderItemId'],
    ticketArr?: ITicket[]
  }): ITicket | null => (ticketArr || tickets || [])
      .find(({ type, orderItemId }) => 
        type?.name === typeName && (!searchOrderItemId || searchOrderItemId === orderItemId)
      ) || null;

  const searchTicketByTypeIds = ({
    typeIds,
    searchOrderItemId,
    ticketArr,
  } : {
    typeIds: number[],
    searchOrderItemId?: ITicket['orderItemId'],
    ticketArr?: ITicket[]
  }): ITicket | null => (ticketArr || tickets || [])
      .find(({ type, orderItemId }) => 
        typeIds.includes(type.id) && (!searchOrderItemId || searchOrderItemId === orderItemId)
      ) || null;

  // --
  // -- return: null - OK, { error? } - Error
  // --
  const [setContactApi] = ticketApi.useSetContactUsMutation();
  const [setWarrantyApi] = ticketApi.useSetWarrantyMutation();
  const [setReturnApi] = ticketApi.useSetReturnMutation();
  const [setCancelApi] = ticketApi.useSetCancelMutation();
  const [setMsg] = ticketApi.useSetMsgMutation();
 
  const addWarranty = async (data: IWarrantyForm): Promise<null | Partial<Record<keyof IWarrantyForm, string>>> => {
    const errs = checkFormStateByZod(warrantyFormScheme, data);
    if (errs) return errs;

    const { attachments, ...req } = data;
    const request: IRequestWarraty = { ...req, ...getOrderAuthParam() };
    const formData = packToFormData({ request, attachments });

    try {
      await setWarrantyApi(formData).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('failed to send warranty claim')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }
  };
  
  const addReturn = async (data: IReturnForm): Promise<null | Partial<Record<keyof IReturnForm, string>>> => {
    const errs = checkFormStateByZod(returnFormScheme, data);
    if (errs) return errs;

    // -- Additional checking
    if(['1', '2', '3'].includes(data.reason) && !data.vin?.trim())
      return { vin: 'VIN # is required' };

    const { attachments, ...req } = data;
    const request: IRequestReturn = { ...req, ...getOrderAuthParam() };
    
    const formData = packToFormData({ request, attachments });

    try {
      await setReturnApi(formData).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('failed to send return request')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }
  };
  
  const addCancel = async (data: ICancelForm): Promise<null | Partial<Record<keyof ICancelForm, string>>> => {
    const errs = checkFormStateByZod(cancelFormScheme, data);
    if (errs) return errs;

    const { attachments, ...req } = data;
    const request: IRequestReturn = { ...req, ...getOrderAuthParam() };
    const formData = packToFormData({ request, attachments });

    try {
      await setCancelApi(formData).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('failed to send cancel order request')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }
  };

  const addMsg = async (data: IMsgForm): Promise<null | Partial<Record<keyof IMsgForm, string>>> => {
    const errs = checkFormStateByZod(MessageScheme, data);
    if (errs) return errs;

    const { attachments, ticketId, ...req } = data;
    const request: IRequestMsg = { ...req, ...getOrderAuthParam() };
    const formData = packToFormData({ request, attachments });

    try {
      await setMsg({ ticketId, formData }).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('failed to send return request')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }
  };
  
  const addContact = async (data: IContactForm): Promise<null | Partial<Record<keyof IContactForm, string>>> => {
    const errs = checkFormStateByZod(contactFormScheme, data);
    if (errs) return errs;

    const { attachments, ...req } = data;
    const request: IRequestConact = { ...req, ...getOrderAuthParam() };
    const formData = packToFormData({ request, attachments });

    try {
      await setContactApi(formData).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('failed to send cancel order request')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }
  };
  
  const handleExit = () => {
    dispatch(removeOrder());
    removeOrderAuthParams();
    navigate(`${process.env.REACT_APP_BASE_URL}ordersignin`);
  }

  return {
    addContact,
    addWarranty,
    addReturn,
    addCancel,
    addMsg,
    searchTicket,
    getTicketsByType,
    searchTicketByTypeIds,
    refreshTickets: refreshTickets(),
    refreshTicketsWithoutToast: refreshTickets(false),
    handleExit,
  }
}
