import { FC } from 'react';
import Checkbox from '../form/Checkbox';
import { NavLink } from 'react-router-dom';
import { SETTING } from '../../setting';

const ShippingPolicesChackbox: FC<{
  agreeded: boolean,
  handleToggle?: () => unknown
}> = ({
  agreeded = false,
  handleToggle = () => {}
}) => {

  return (
    <div className="flex gap-2 items-center">
      <div className="">
        <Checkbox
        isNoLabelArea
          name="agreement"
          size="tiny"
          value={agreeded} 
          handleToggle={handleToggle}
        />
      </div>
      <div className="flex">
        By proceeding, you understand our&nbsp;
        <NavLink
          to={SETTING.currentPortal.agreementUrl}
          target="_blank"
          className="text-red-600 font-bold"
        >
          shipping and returns policies
        </NavLink>
        &nbsp;under which this purchase is made.        
      </div>
  </div>
  );
}

export default ShippingPolicesChackbox;
