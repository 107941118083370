import { FC } from "react";
import { icons } from "../../icons/IconSet";
import { IStatusLog } from "../../model/IOrder";
import { statusColor } from "./StatusColors";
import parseISOToString from "../../utils/parseISOToString";

const StatusLog: FC<{ log?: IStatusLog[], created?: string }> = ({ log, created = '' }) => (
  <div className="flex">
  <div className="flex relative">
    {[
      { status: 'Initiated', date: created, id: 0, text: '' } as IStatusLog,
      ...(log || [])
    ].map(({ status, date, id }, i, logArr) => {
      const gradientFrom = statusColor?.[logArr?.[i-1]?.status ?? status] || 'gray';
      const gradientTo = statusColor?.[status] || 'gray';
        
      return (
        <div className="relative flex-1 min-w-[200px]" key={id}>
          <div
            style={{
              left: logArr?.[i-1] ? 'calc(-50% + 18px)' : '50%',
              right: logArr?.[i+1] ? 'calc(50% + 18px)' : '50%',
              background: `linear-gradient(90deg, ${gradientFrom} 0%, ${gradientTo} 100%)`,
            }}
            className="h-[5px] absolute top-[15px]"
          />
          <div className="flex flex-col items-center">
            <div
              style={{ backgroundColor: statusColor?.[status]|| 'gray' }}
              className="flex rounded-[50%] w-[35px] h-[35px] items-center justify-center color-white"
            >
              {icons('ok', { stroke: 'white', width: '20px' })}
            </div>
            <div
              className="mt-4 font-bold"
              style={{ color: statusColor?.[status] || 'gray' }}
            >
              {status}
            </div>
            <div className="">{parseISOToString(date, false)}</div>
          </div>
          
        </div>
      )})
    }
  </div>
  </div>
);

export default StatusLog;
